/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from '@tanstack/react-query';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ForgotPasswordAPI } from '../../../api/auth.api';
import './index.scss';
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CContainer,
  CFooter,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CLink,
  CListGroup,
  CLoadingButton,
  CRow,
  CSpinner,
} from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import appStore from '../../../assets/stores/app-store.svg';
import googlePlay from '../../../assets/stores/google-play.svg';
import headerLogo from '../../../assets/brand/mainLogo2.svg';
import { cilSettings, cilCheckAlt, cilUser, cilAccountLogout } from '@coreui/icons';
import appointmen from './appointment-missed.svg';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { CardFooter, CardHeader } from 'react-bootstrap';
import axios from 'axios';
import env from '../../../env';
const Booking = () => {
  const [bookingDetails, setBookingDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const packageName = 'com.beautybook.customer';
  const appleId = '6450213994';

  const appStoreLink = `itms-apps://itunes.apple.com/app/id${appleId}`;
  const playStoreLink = `market://details?id=${packageName}`;

  const BookingDetails = async (bookingId) => {
    try {
      const response = await axios.get(
        `${env.app.backend_url}/api/v1/app/user/foreignUserGift?bookingId=${bookingId}`,
      );
      if (response.data.success) {
        toast.success('Booking retrieved successfully');
        setBookingDetails(response.data.receipt);

        // navigate('/signin')
      } else {
      }
    } catch (error) {}

    setLoading(false);
  };

  const splitLocation = (loc: string) => {
    if (!loc) return <></>;
    const siplited = loc.split(',');
    if (siplited.length < 2) return loc;
    return (
      <>
        {siplited[0]} <br /> {siplited[1]}{' '}
      </>
    );
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlParams.entries());
    const bookingId = queryParams.id;
    if (bookingId) BookingDetails(bookingId);
    else setLoading(false);

    // https://beautybookserver.com/api/v1/app/user/foreignUserGift?bookingId=c0b37a87-ca49-4e41-9bb8-e48df1ba674d
  }, []);

  return (
    <>
      <CCard style={{ minHeight: '80dvh' }}>
        <CardHeader className="booking-header">
          <div className="booking-logo">
            <CImage
              width={'200px'}
              height={'150px'}
              align="start"
              alt="logo"
              src={headerLogo}
              className="profile-picture"
            />
          </div>
        </CardHeader>
        <CCardBody>
          {loading ? (
            <CRow className="justify-content-center">
              <CSpinner className="text-center" color="primary" />  
            </CRow>
          ) : bookingDetails ? (
            <>
              <CRow className="justify-content-center">
                <CCol xs="6" lg="3">
                  <h4 className="mb-4 color-primary">Booking Details</h4>
                </CCol>
                <CCol xs="6" lg="3">
                  <h4 className="mb-4 color-primary text-end">تفاصيل الحجز</h4>
                </CCol>
              </CRow>
              <CRow className="justify-content-center">
                <div className="text-center">
                  <img src={bookingDetails.qr} alt="QR Code" style={{ maxWidth: '150px' }} />
                  <p className="mt-1"> {bookingDetails.bookingDate}</p>
                </div>
              </CRow>
              <CRow className="justify-content-center">
                <CCol xs="6" lg="3">
                  <div className="mb-4">
                    <p className="font-weight-bold color-primary">Salon:</p>
                    <p>
                      {bookingDetails.salon.name} <br />{' '}
                      {splitLocation(bookingDetails.salon.location)}
                    </p>

                    {/* Add more salon details as needed */}
                  </div>
                </CCol>

                {/* Arabic Content */}
                <CCol xs="6" lg="3">
                  <div className="mb-4" style={{ textAlign: 'right', direction: 'rtl' }}>
                    <p className="font-weight-bold color-primary">الصالون:</p>
                    <p>
                      {bookingDetails.salon.nameAr} <br />{' '}
                      {splitLocation(bookingDetails.salon.locationAr)}
                    </p>

                    {/* Add more salon details as needed */}
                  </div>
                </CCol>
              </CRow>

              <CRow className="justify-content-center">
                <CCol xs="6" lg="3">
                  <div className="mb-4" style={{ textAlign: 'left' }}>
                    <p className="font-weight-bold color-primary">Services:</p>

                    <ul className="list-unstyled">
                      {bookingDetails.services.map((service, index) => (
                        <li key={index}>
                          <CIcon icon={cilCheckAlt} className="mr-2" /> {service.title}{' '}
                          {`[${service.date} ${service.time}]`}{' '}
                        </li>
                      ))}
                    </ul>

                    {/* Add more salon details as needed */}
                  </div>
                </CCol>

                {/* Arabic Content */}
                <CCol xs="6" lg="3">
                  <div className="mb-4" style={{ direction: 'rtl' }}>
                    <p className="font-weight-bold color-primary">الخدمات:</p>

                    <ul className="list-unstyled p-0">
                      {bookingDetails.services.map((service, index) => (
                        <li key={index}>
                          <div>
                            <CIcon icon={cilCheckAlt} className="ml-2" />
                            {service.titleAr} {`[${service.date} ${service.time}]`}
                            <div></div>
                          </div>
                        </li>
                      ))}
                    </ul>

                    {/* Add more salon details as needed */}
                  </div>
                </CCol>
              </CRow>
            </>
          ) : (
            <CRow className="justify-content-center mt-5 h-100">
              <CCol xs="auto">
                <div className="text-center">
                  <img
                    src={appointmen}
                    alt="QR Code"
                    style={{ maxWidth: '100%', maxHeight: '40dvh' }}
                  />

                  <div className="text-center">
                    <h2 className="text-center mt-5 color-primary">{'BOOKING NOT FOUND'}</h2>
                  </div>
                </div>
              </CCol>
            </CRow>
          )}
        </CCardBody>
      </CCard>

      <CFooter className="booking-footer">
        <div style={{ width: '100%' }}>
          <CRow className="justify-content-center align-items-center pb-5">
            <CCol xs="auto">
              <a href={appStoreLink} className="text-white mr-4">
                <img
                  src={appStore}
                  alt="appStore"
                  style={{ maxWidth: '150px', maxHeight: '50px' }}
                />
              </a>
            </CCol>
            <CCol xs="auto">
              <a href={playStoreLink} className="text-white">
                <img
                  src={googlePlay}
                  alt="googlePlay"
                  style={{ maxWidth: '150px', maxHeight: '50px' }}
                />
              </a>
            </CCol>
          </CRow>
        </div>
      </CFooter>
    </>
  );
};

export default Booking;
