import CryptoJS from 'crypto-js';
import { AES } from 'crypto-js';

const algorithm = 'aes-256-cbc'; // Encryption algorithm
const key = '91ab031b8714d85fab2b7870be57b2253286976ee36b2a14b0674fe03e914ac9'; // Generate a random key

 
// URL-safe base64 decoding
const urlSafeBase64Decode = (str) => {
  let base64 = str.replace(/-/g, '+').replace(/_/g, '/');
  while (base64.length % 4) {
    base64 += '=';
  }
  return base64;
};

// Encryption function with key and IV
export const encrypt = (text) => {
  const encryptedData = AES.encrypt(text, key).toString();

  return encryptedData;
};

// Decryption function with key and IV
export const decrypt = (cipherText:string) => {
  try {
    const decryptedData = AES.decrypt(cipherText, key).toString(CryptoJS.enc.Utf8);
    
    return decryptedData;
  } catch (error) {
    console.log(error);
    
    return error;
  }
};
