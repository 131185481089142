import React, { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import {validateTamaraPayment } from '../../api/common.api';
import { AxiosResponse } from 'axios';

declare global {
  interface Window {
    ReactNativeWebView?: any;
    webkit?: any;
    wpwlOptions?: any;
  }
}

interface ResponseData {
  message: string;
  code: string;
  description: string;
  hyperpaySucess: boolean;
  success: boolean;
}

interface IApiResponse {
  data: ResponseData;
}

const TamaraPaymentCallBack = () => {
  const [searchParams] = useSearchParams();

  const { mutate: mutateValidatePayment, isLoading } = useMutation(validateTamaraPayment);

  // TODO add vaidite paymnet

  useEffect(() => {
    try {
      //localhost:3000/checkPaymentStatus?id=tamara&paymentStatus=canceled&orderId=aeb7d2e3-02eb-4e42-9829-e24a26ed7df6

        const isTamara: boolean = (searchParams.get('id') ?? '') == 'tamara';
        const paymentStatus: string = (searchParams.get('paymentStatus') ?? '')  ;
        const orderId: string = (searchParams.get('orderId') ?? '')  ;


      mutateValidatePayment(
        {
          orderId: orderId,
          paymentStatus: paymentStatus
        },
        {
          onSuccess: (res: IApiResponse) => {
            try {
              const postData = {
                type: 'payment',
                payload: { id: orderId, success: true },
              };
  
              
              if (res.data.success) {
  
                if (window.ReactNativeWebView) {
                  window.ReactNativeWebView?.postMessage(JSON.stringify(postData));
                  return;
                }
  
                if (
                  window.webkit &&
                  window.webkit.messageHandlers &&
                  window.webkit.messageHandlers.ReactNativeWebView
                ) {
                  window.webkit.messageHandlers.ReactNativeWebView.postMessage(
                    JSON.stringify(postData),
                  );
                  return;
                }
              } else {
                if (window.ReactNativeWebView) {
                  window.ReactNativeWebView?.postMessage(
                    JSON.stringify({
                      success: false,
                      Description: "Ops! Something went wrong. Please try again later.",
                    }),
                  );
  
                  return;
                }
  
                if (
                  window.webkit &&
                  window.webkit.messageHandlers &&
                  window.webkit.messageHandlers.ReactNativeWebView
                ) {
                  window.webkit.messageHandlers.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      success: false,
                      Description: "Ops! Something went wrong. Please try again later.",
                    }),
                  );
  
                  return;
                }
              }
            } catch (error) {
              window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  type: 'payment',
                  payload: {
                    id: orderId,
                    success: false,
                    false: false,
                    error: error,
                    paymentStatus: paymentStatus
                  },
                }),
              );
            }
          },
          onError: (err) => {

            console.log(err);
            
            window.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: 'payment',
                payload: {
                  id: orderId,
                  success: false,
                  error: err,
                  paymentStatus: paymentStatus
                },
              }),
            );
          },
        },
      );
    } catch (e) {
      console.error('react app', e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <h3>{'loading ...'}</h3>
    </div>
  ) : (
    <div />
  );
};

export default TamaraPaymentCallBack;
