/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { ForgotPasswordAPI } from '../../../api/auth.api';
import './index.scss';
import {
  CButton,
  CCardText,
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CContainer,
  CFooter,
  CForm,
  CFormInput,
  CImage,
  CFormSelect,
  CInputGroup,
  CLink,
  CListGroup,
  CLoadingButton,
  CRow,
  CSpinner,
  CFormFloating,
  CInputGroupText,
  CAlert,
  CAlertHeading,
  CHeaderDivider,
  CCardImage,
  CCardTitle,
} from '@coreui/react-pro';
import { useMutation, useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { cilPlus } from '@coreui/icons';
import { CFormLabel } from '@coreui/react-pro';

import CIcon from '@coreui/icons-react';
import appStore from '../../../assets/stores/app-store.svg';
import googlePlay from '../../../assets/stores/google-play.svg';
import headerLogo from '../../../assets/brand/mainLogo2.svg';
import { useFormik } from 'formik';
import Password from '../../../components/password';
import { acceptedImageFileTypes } from '../../../types';
import UseToast from '../../../hooks/useToast';
import AddAdminSchema from '../../private/Staff/modals/formcheck';
import { UploadProfileAPI } from '../../../api/common.api';
import { decrypt } from '../../../libs/crypro';
import { OwnerRegistration, SalonInfo } from '../../../api/provider.api';
import { CardHeader } from 'react-bootstrap';
import { object, ref, string } from 'yup';
import {
  arabicLettersOnlyRegex,
  emailValidation,
  englishLettersOnlyRegex,
  saudiaPhoneNumber,
} from '../../../utils/validator';

type salonInfo = {
  name: string;
  nameAr: string;
  location: string;
  locationAr: string;
  coverImage: {
    link: string;
  };
};
const OwnerRegister = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const packageName = 'com.beautybook.customer';
  const appleId = '6450213994';

  const appStoreLink = `itms-apps://itunes.apple.com/app/id${appleId}`;
  const playStoreLink = `market://details?id=${packageName}`;
  const [formData, setformData] = useState<any>();

  const [countryCode, setcountryCode] = useState<any>('+966');
  const [updatedProfilePicture, setUpdatedProfilePicture] = useState<Blob | undefined | any>();
  const [iconShow, setIconShow] = useState<boolean>(false);
  const [iconShowConfirm, setIconShowConfirm] = useState<boolean>(false);
  const [salon, setSalon] = useState<any>({
    name: '',
    nameAr: '',
    location: '',
    locationAr: '',
    coverImage: {
      link: '',
    },
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = _.get(e, 'target.value');
    if (value !== ' ') {
      if (value.length === 10) {
        return null;
      } else {
        setFieldValue('contactNumber', value);
      }
    }
  };

  const goHome = () => {
    window.location.replace('https://beautybook.sa/');
  };

  const { mutate, isLoading } = useMutation(OwnerRegistration, {
    onSuccess(data, variables, context) {
      const SuccessMsg = data?.data?.message;
      UseToast(SuccessMsg, 'success');
      goHome();

    },
    onError: (data: any) => {
      const ErrMsg = data?.data?.message;
      console.log(ErrMsg, 'error');
    },
  });

  const { mutate: getSalonInfo } = useMutation(SalonInfo, {
    onSuccess(data, variables, context) {
      const SuccessMsg = data?.data?.message;
      setSalon(data?.data?.details);
      setFieldValue('providerId', data?.data?.details?.provider?.id);
      UseToast(SuccessMsg, 'success');
      setLoading(false);
    },
    onError: (data: any) => {
      goHome();
    },
  });

  const iconHideShow = () => {
    setIconShow((prevState) => {
      return !prevState;
    });
  };

  const iconHideShowConfirm = () => {
    setIconShowConfirm((prevState) => {
      return !prevState;
    });
  };

  const { mutate: uploadIcon } = useMutation(UploadProfileAPI, {
    onSuccess(data: any, variables, context) {
      mutate({
        name: values?.name,
        nameAr: values?.nameAr,
        providerId: values?.providerId,
        imageId: data?.data?.files[0]?.id,
        email: values?.email,
        password: values?.password,
        language: 'en',
        passwordConfirmation: values?.confirmPassword,
        contactNumber: values.contactNumber,
        countryPrefix: countryCode,
        role: values.role,
      });
    },
  });

  const handleChangeProfilePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (files[0].type.includes('image/')) {
          setUpdatedProfilePicture(_.get(event, 'target.result'));

        } else {
          console.log('Not found');

          setUpdatedProfilePicture('');
        }
      };
      reader.readAsDataURL(files[0]);
      const formData = new FormData();
      formData.append('filesToUpload', files[0]);
      setformData(formData);
      setFieldValue('profilePicture', formData);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlParams.entries());
    const providerId = queryParams.data;

    const decryptedId = decrypt(providerId.replaceAll(' ', '+'));


    if (!decryptedId) {
      goHome();
    }

    getSalonInfo(decryptedId);

  
  }, []);

  const { handleSubmit, values, handleChange, handleBlur, errors, touched, setFieldValue } =
    useFormik({
      validationSchema: object().shape({
        name: string()
          .min(2, 'Minimum 2 characters are required.')
          .max(70, 'Too Long!')
          .required('Please enter  name.')
          .matches(englishLettersOnlyRegex, 'Just English Language without special characters'),
        // .matches(/^[A-Za-z\g]*$/, "Just English Language without special characters."),
        nameAr: string()
          .min(2, 'Minimum 2 characters are required.')
          .max(70, 'Too Long!')
          .required('Please enter arabic name .')
          .matches(arabicLettersOnlyRegex, 'Just Arabic Language without special characters'),
        email: string()
          .required('Please enter email.')
          .matches(emailValidation, 'please enter valid email address'),
        password: string()
          .required('Please enter password.')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            'Password must contain at least 8 characters, one uppercase, one number and one special character.',
          ),
        confirmPassword: string()
          .required('Please enter password.')
          .required('Please enter confirm password.')
          .oneOf([ref('password')], 'Password and confirm password does not match.'),

        contactNumber: string()
          .required('Please enter  contact number.')
          .min(9, 'Please enter valid number.')
          .matches(saudiaPhoneNumber, 'please enter valid 9 digits phone number'),
        profilePicture: string().required('Please upload profile picture.'),
      }),
      initialValues: {
        name: '',
        nameAr: '',
        profilePicture: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: 'Owner',
        contactNumber: '',
        providerId: '',
      },
      enableReinitialize: true,
      onSubmit: async (values) => {
        console.log(values);

        // eslint-disable-next-line no-restricted-globals
        event?.preventDefault();
        if (formData === undefined) {
          mutate({
            name: values?.name,
            nameAr: values?.nameAr,
            providerId: values?.providerId,
            email: values?.email,
            password: values?.password,
            contactNumber: values?.contactNumber,
            role: values?.role,
          });
        } else {
          uploadIcon(formData);
        }
      },
    });

  const SalonCard = ({ salon }) => {
    return (
      <CCard
        style={{
          maxWidth: '560px',
          margin: 'auto',
          border: '1px solid #ddd',
          borderRadius: ' 10px',
          backgroundColor: '#f8f9fa',
          width: '100%',
          marginBlock: '10px',
          
          
        }}
      className="d-none d-sm-none d-md-node d-lg-block"
      >
        <CCardImage orientation="top" src={salon.coverImage?.link || '/user.png'} height={300} />
        <CCardBody>
          <CRow>
            <CCol>
              <CCardTitle>{salon?.name}</CCardTitle>
              <CCardText style={{ textTransform: 'capitalize' }}>{salon?.location}</CCardText>
              <CCardText>{salon?.provider?.contactNumber}</CCardText>

              {/* <CRow>
                <CCol>
                  <CCardText>Open {salon?.currentOpenTime?.startTime}</CCardText>
                </CCol>
                <CCol>
                  <CCardText>Close {salon?.currentOpenTime?.endTime}</CCardText>
                </CCol>
              </CRow> */}
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    );
  };

  return (
    <>
      <CCard className="reg-card">
        <CardHeader className="booking-header">
          <div className="booking-logo">
            <CImage
              width={'200px'}
              height={'150px'}
              align="start"
              alt="logo"
              src={headerLogo}
              className="profile-picture"
            />
          </div>
        </CardHeader>
        <div className="alertText">
          <CAlert color="primary">
            <CAlertHeading>Welcome to BeautyBook</CAlertHeading>
            <p>
              We are excited to introduce you to the Beauty Book App, designed to bring convenience
              and growth to your salon.
              <br /> Our app offers everything you need to manage your business, from scheduling
              appointments to creating promotional campaigns.
            </p>
            <hr />
            <p className="mb-0">Unlock the Full Potential of Your Salon with Beauty Book App.</p>
          </CAlert>
        </div>
        <CCardBody className="reg-card-body">
          {loading ? (
            <CRow className="justify-content-center">
              <CSpinner className="text-center" color="primary" />
            </CRow>
          ) : (
            <>
              <div className="salon-info-container">
                {salon?.id ? <SalonCard salon={salon} /> : null}

                <div className="admin-profile-container">
                  <CCardBody className="admin-profile-body">
                    <h6 className="text-center">Profile Picture</h6>
                    <div className="profile-picture-wrapper">
                      <div className="profile-picture-container">
                        {updatedProfilePicture ? (
                          <CImage
                            align="center"
                            src={updatedProfilePicture || '/user.png'}
                            width={100}
                            height={100}
                          />
                        ) : (
                          <>
                            <input
                              className="upload-profile-picture-input"
                              type="file"
                              name="profilePicture"
                              id="profilePicture"
                              accept={acceptedImageFileTypes}
                              style={{
                                opacity: 0,
                              }}
                              onChange={handleChangeProfilePicture}
                              onBlur={handleBlur}
                            />

                            <CFormLabel
                              htmlFor="profilePicture"
                              className="upload-file-block"
                              style={{ opacity: updatedProfilePicture ? 0 : 1 }}
                            >
                              <div className="text-center">
                                <div className="mb-2">
                                  <i className="fa fa-camera fa-2x"></i>
                                </div>
                                <div
                                  className="text-uppercase"
                                  style={{ opacity: updatedProfilePicture ? 0 : 1 }}
                                >
                                  <CIcon icon={cilPlus} />
                                </div>
                              </div>
                            </CFormLabel>
                          </>
                        )}
                      </div>
                    </div>
                    {errors?.profilePicture && touched?.profilePicture && (
                      <p
                        style={{
                          justifyContent: 'center',
                          display: 'flex',
                          color: '#e55353',
                          marginTop: '15px',
                          fontSize: '14px',
                        }}
                      >
                        {errors?.profilePicture}
                      </p>
                    )}

                    <CForm noValidate onSubmit={handleSubmit}>
                      <CFormFloating className="mb-3 mt-5">
                        <CFormInput
                          placeholder="Arabic Name"
                          autoComplete="Arabic Name"
                          id="salonName"
                          maxLength={70}
                          //formik validation
                          value={salon.name}
                          disabled={true}
                        />
                        <CFormLabel htmlFor="salonName">Salon</CFormLabel>
                      </CFormFloating>

                      <CFormFloating className="mb-3">
                        <CFormInput
                          placeholder="Name"
                          autoComplete="name"
                          id="name"
                          maxLength={70}
                          //formik validation
                          value={values.name.trimStart()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          invalid={touched.name && Boolean(errors.name)}
                          feedback={touched.name && Boolean(errors.name) && errors.name}
                          disabled={isLoading}
                        />
                        <CFormLabel htmlFor="name">Name</CFormLabel>
                      </CFormFloating>

                      <CFormFloating className="mb-3 mt-2">
                        <CFormInput
                          placeholder="Arabic Name"
                          autoComplete="Arabic Name"
                          id="nameAr"
                          maxLength={70}
                          //formik validation
                          value={values.nameAr.trimStart()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          invalid={touched.nameAr && Boolean(errors.nameAr)}
                          feedback={touched.nameAr && Boolean(errors.nameAr) && errors.nameAr}
                          disabled={isLoading}
                        />
                        <CFormLabel htmlFor="nameAr">Arabic Name</CFormLabel>
                      </CFormFloating>

                      <CFormFloating className="mb-3">
                        <CFormInput
                          placeholder="Email"
                          maxLength={70}
                          id="email"
                          disabled={isLoading}
                          onBlur={handleBlur}
                          value={values.email}
                          onChange={handleChange}
                          autoComplete="new-password"
                          invalid={touched.email && Boolean(errors.email)}
                          feedback={touched.email && Boolean(errors.email) && errors.email}
                        />
                        <CFormLabel htmlFor="email">Email</CFormLabel>
                      </CFormFloating>

                      <CInputGroup className="mb-3 mt-2">
                        <CInputGroupText id="inputGroup-sizing-default">
                          {countryCode}
                        </CInputGroupText>

                        <CFormInput
                          type="number"
                          min={0}
                          maxLength={9}
                          placeholder="Contact Number"
                          autoComplete="contactNumber"
                          id="contactNumber"
                          disabled={isLoading}
                          onWheel={(e) => e.currentTarget.blur()}
                          //formik validation
                          value={values.contactNumber}
                          onChange={handleOnChange}
                          onBlur={handleBlur}
                          invalid={touched.contactNumber && Boolean(errors.contactNumber)}
                          feedback={
                            touched.contactNumber &&
                            Boolean(errors.contactNumber) &&
                            errors.contactNumber
                          }
                          onKeyDown={(e) =>
                            ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                          }
                        />
                      </CInputGroup>

                      <CInputGroup className="">
                        <CFormInput
                          onPaste={(e: any) => {
                            e.preventDefault();
                            return false;
                          }}
                          type={iconShow ? 'text' : 'password'}
                          maxLength={16}
                          placeholder="Password"
                          autoComplete="new-password"
                          id="password"
                          disabled={isLoading}
                          onBlur={handleBlur}
                          value={values.password}
                          onChange={handleChange}
                          invalid={touched.password && Boolean(errors.password)}
                        />
                        <Password iconHideShow={iconHideShow} iconShow={iconShow} />
                      </CInputGroup>
                      <p className="text-danger f-25 mb-3">
                        {touched.password && Boolean(errors.password) && errors.password}
                      </p>
                      <CInputGroup className="">
                        <CFormInput
                          onPaste={(e: any) => {
                            e.preventDefault();
                            return false;
                          }}
                          type={iconShowConfirm ? 'text' : 'password'}
                          maxLength={16}
                          placeholder="Confirm Password"
                          autoComplete="new-password"
                          id="confirmPassword"
                          disabled={isLoading}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                          onChange={handleChange}
                          invalid={touched.confirmPassword && Boolean(errors.confirmPassword)}
                        />
                        <Password iconHideShow={iconHideShowConfirm} iconShow={iconShowConfirm} />
                      </CInputGroup>

                      <p className="text-danger f-25">
                        {touched.confirmPassword &&
                          Boolean(errors.confirmPassword) &&
                          errors.confirmPassword}
                      </p>

                      <div className="add-action-buttons">
                        <CLoadingButton
                          className="btn"
                          type="submit"
                          disabled={isLoading}
                          loading={isLoading}
                        >
                          Submit
                        </CLoadingButton>
                      </div>
                    </CForm>
                  </CCardBody>
                </div>
              </div>
            </>
          )}
        </CCardBody>
      </CCard>

      <CFooter className="booking-footer">
        <div style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              gap: '1rem',
            }}
          >
            <a href={appStoreLink} style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={appStore} alt="appStore" style={{ width: '150px', height: '50px' }} />
            </a>
            <a href={playStoreLink} style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={googlePlay} alt="googlePlay" style={{ width: '150px', height: '50px' }} />
            </a>
          </div>
        </div>
      </CFooter>
    </>
  );
};

export default OwnerRegister;
