import request from '../config/request';

 export const quickPaymentBookingDetails = async (id: string) => {
  const response = await request({
    url: `/app/user/booking/quick-payment/${id}`,
    method: 'GET',
    customURl: true,
  });

  return response;
};


export const quickPaymentCheckout = async (data: any) => {
  const response = await request({
    url: `/app/user/booking/quick-payment/guest`,
    method: 'POST',
    body:data,
    customURl: true,
  });

  return response;
};
