/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';

import './index.scss';
import {
  CButton,
  CCol,
  CFormInput,
  CFormLabel,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
} from '@coreui/react-pro';
import { useMutation, useQuery } from '@tanstack/react-query';
import _, { debounce } from 'lodash';

import appStore from '../../../assets/stores/app-store.svg';
import googlePlay from '../../../assets/stores/google-play.svg';
import headerLogo from '../../../assets/brand/mainLogo2.svg';
import { useFormik } from 'formik';

import appointment from '../Booking/appointment-missed.svg';
import { CardHeader } from 'react-bootstrap';
import { object, ref, string } from 'yup';
import { saudiaPhoneNumber } from '../../../utils/validator';
import { useLocation, useNavigate } from 'react-router-dom';
import UseToast from '../../../hooks/useToast';
import { customerSignIn } from '../../../api/customerPortal.api';
import InvoiceSummary from './InvoiceSummary';
import {
  quickPaymentBookingDetails,
  quickPaymentCheckout,
} from '../../../api/customerCheckout.api';
import moment from 'moment';
import env from '../../../env';

const CustomerCheckout = () => {
  const [showPayments, setShowPayments] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<string>('');

  const packageName = 'com.beautybook.customer';
  const appleId = '6450213994';

  const appStoreLink = `itms-apps://itunes.apple.com/app/id${appleId}`;
  const playStoreLink = `market://details?id=${packageName}`;

  const [selectedPayment, setSelectedPayment] = useState('');
  const [bookingDetails, setBookingDetails] = useState<any>(null);

  const paymentMethods = [
    { label: 'Visa', id: 'VISA' },
    { label: 'Master Card', id: 'MASTER' },
    { label: 'Mada', id: 'MADA' },
    { label: 'STC Pay', id: 'STC_PAY' },
    { label: 'Apple Pay', id: 'APPLEPAY' },
  ];

  const handlePaymentChange = (method) => {
    setSelectedPayment(method);
  };

  const { mutate: getCheckoutLink, isLoading } = useMutation(quickPaymentCheckout, {
    onSuccess(data, variables, context) {
      const SuccessMsg = data?.data?.message;
      const checkoutID = data?.data?.checkoutId;

      const redirectUrl =
        env.app.react_root_app +
        `/paymentCallBack?amount=${getCost(
          'after',
        )}&checkoutId=${checkoutID}&brand=${selectedPayment?.toUpperCase()}&baseUrl=${
          env.app.react_root_app
        }/guest/&local=en&isProduction=${env.app.backend_url == 'https://admin.beautybook.sa'}`;

      window.location.assign(redirectUrl);

      // UseToast(SuccessMsg, 'success');
    },
    onError: (data: any) => {
      const ErrMsg = data?.data?.message;
      console.log(ErrMsg, 'error');
    },
  });

  const { mutate } = useMutation(quickPaymentBookingDetails, {
    onSuccess(data: any, variables, context) {
      const SuccessMsg = data?.data?.message;
      setBookingDetails(data?.data?.booking);

      // UseToast(SuccessMsg, 'success');
      setLoading(false);
    },
    onError: (data: any) => {
      if (typeof data === 'string') {
        setMessage(data);
      } else {
        const ErrMsg = data?.data?.message;
        setMessage(`${ErrMsg}`);
      }
      // setMessage(data);
      setLoading(false);
      setTimeout(() => {
        // goHome();
      }, 5000);
    },
  });

  const getCost = (type: string) => {
    let total = bookingDetails?.selectedSubServices?.reduce((total, item: any) => {
      const isDiscountApplied = moment(item.validTillDate).add(1, 'days').isAfter(moment());
      return total + (isDiscountApplied ? item.price : item.originalPrice);
    }, 0);

    if (type === 'before') {
      return total + (total * bookingDetails?.VAT || 0) / 100;
    } else {
      if (bookingDetails?.salonDiscount > 0) {
        const discountAmount = (bookingDetails?.salonDiscount / 100) * total;
        const totalCost = total - discountAmount;
        total = Number(totalCost);
      }

      if (bookingDetails?.bbookDiscount > 0) {
        const discountAmount = (bookingDetails?.bbookDiscount / 100) * total;
        const totalCost = total - discountAmount;
        total = Number(totalCost);
      }

      return total + (total * bookingDetails?.VAT || 0) / 100;
    }
  };

  const isAppleDevice = () => {
    const userAgent = navigator.userAgent;
    return /iPhone|iPad|iPod|Macintosh/.test(userAgent) ;
  };

  const processPayment = () => {
    if (selectedPayment) {
      const data = {
        salonId: bookingDetails.selectedSalon.id,
        totalAmount: getCost('after')?.toFixed(2),
        paymentMethod: selectedPayment === 'Cash' ? 'Cash' : 'Online',
        VAT: bookingDetails.VAT || 0,
        cardType: selectedPayment,
        bookingDetails: bookingDetails.selectedSubServices?.map((item: any) => ({
          providerCategoryId: item.id,
        })),
        bookingId: bookingDetails.bookingId,
        salonDiscount: bookingDetails.salonDiscount ? Number(bookingDetails.salonDiscount) : 0,
        bbookDiscount: bookingDetails.bbookDiscount,
      };

      getCheckoutLink(data);
    } else {
      UseToast('Please select a payment method', 'error');
    }
  };
  const goHome = () => {
    window.location.replace('https://beautybook.sa/');
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlParams.entries());
    const bookingId = queryParams.booking;
    if (!bookingId) {
      setLoading(false);
    } else {
      mutate(bookingId);
    }
  }, []);

  return (
    <div className="main-container">
      <CardHeader className="main-header">
        <CImage
          width={'200px'}
          height={'150px'}
          align="start"
          alt="logo"
          src={headerLogo}
          className="profile-picture"
        />
      </CardHeader>

      <div className="main-content">
        {loading ? (
          <CRow className="justify-content-center">
            <CSpinner className="text-center" color="primary" />
          </CRow>
        ) : !bookingDetails ? (
          <CRow className="justify-content-center mt-5">
            <CCol xs="auto">
              <div className="text-center">
                <img
                  src={appointment}
                  alt="QR Code"
                  style={{ maxWidth: '80%', maxHeight: '40dvh' }}
                />

                <div className="text-center">
                  <h2 className="text-center mt-5 color-primary">
                    {message ? message : 'BOOKING NOT FOUND'}
                  </h2>
                </div>
              </div>
            </CCol>
          </CRow>
        ) : (
          <div className="page-container">
            <div className="page-content">
              {!showPayments ? (
                <div>
                  <h5 style={{ fontWeight: 'bold' }}>{bookingDetails.selectedSalon.name}</h5>
                  <h6>The payment is verified by the service provider.</h6>

                  <InvoiceSummary bookingDetails={bookingDetails} />
                </div>
              ) : (
                <div>
                  {paymentMethods
                    .filter((method) => {
                      if (method.id === 'APPLEPAY') {
                        // check if device is apple device

                        if (isAppleDevice()) return true;
                        else return false;
                      } else {
                        return true;
                      }
                    })
                    .map((method) => (
                      <label
                        key={method.id}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '10px',
                          border: '1px solid #ccc',
                          borderRadius: '5px',
                          margin: '10px 0',
                          cursor: 'pointer',
                        }}
                      >
                        <img
                          src={`/payments/${method.id.toLowerCase()}.png`} // Replace with your icons
                          alt={method.label}
                          style={{
                            width: '80px',
                            height: '40px',
                            marginRight: '10px',
                            objectFit: 'contain',
                          }}
                        />
                        <span style={{ flex: 1 }}>{method.label}</span>
                        <input
                          type="radio"
                          name="paymentMethod"
                          value={method.id}
                          checked={selectedPayment === method.id}
                          onChange={() => handlePaymentChange(method.id)}
                          style={{ accentColor: '#4B0082' }}
                        />
                      </label>
                    ))}
                </div>
              )}

              <CButton
                type="submit"
                color="primary"
                className="login-btn w-100"
                disabled={isLoading}
                onClick={() => {
                  if (showPayments) {
                    processPayment();
                  } else {
                    setShowPayments(true);
                  }
                }}
              >
                {isLoading ? (
                  <>
                    <CSpinner size="sm" />
                    <span className="ms-2">Loading...</span>
                  </>
                ) : showPayments ? (
                  'Proceed'
                ) : (
                  'Next'
                )}
              </CButton>
            </div>
          </div>
        )}
      </div>

      <div className="main-footer">
        <a href={appStoreLink} className="store-container">
          <img src={appStore} alt="appStore" className="store-img" />
        </a>
        <a href={playStoreLink} className="store-container">
          <img src={googlePlay} alt="googlePlay" className="store-img" />
        </a>
      </div>
    </div>
  );
};

export default CustomerCheckout;
