import React, { useEffect } from 'react';

const DeepLinkHandler = () => {
  const handleDeepLink = () => {
    const isAndroid = /Android/.test(navigator.userAgent);
    const urlParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlParams.entries());
    const salonId = queryParams.share;
    const qc = queryParams.qc;
    
    if (qc) {
      const packageName = 'com.beautybook.customer';
      const appleId = '6450213994';

      const appStoreLink = `itms-apps://itunes.apple.com/app/id${appleId}`;
      const playStoreLink = `market://details?id=${packageName}`;

      if (isAndroid) {
        const appLink = `beautybookapp://qc?id=${qc}`;
        window.location.href = appLink;
      } else {
        const appLink = `com.beautybook.customer://qc?id=${qc}`;
        window.location.href = appLink;
      }

      setTimeout(() => {
        // If the deep link hasn't opened in a certain time, redirect to the app store
        window.location.href = isAndroid ? playStoreLink : appStoreLink;
      }, 3000);
    } else if (salonId) {
      const packageName = 'com.beautybook.customer';
      const appleId = '6450213994';

      const appStoreLink = `itms-apps://itunes.apple.com/app/id${appleId}`;
      const playStoreLink = `market://details?id=${packageName}`;

      if (isAndroid) {
        const appLink = `beautybookapp://salon?id=${salonId}`;
        window.location.href = appLink;
      } else {
        const appLink = `com.beautybook.customer://salon?id=${salonId}`;
        window.location.href = appLink;
      }

      setTimeout(() => {
        // If the deep link hasn't opened in a certain time, redirect to the app store
        window.location.href = isAndroid ? playStoreLink : appStoreLink;
      }, 3000);
    } else {
      if (isAndroid) {
        const packageName = 'com.beautybook.customer';
        const playStoreLink = `market://details?id=${packageName}`;
        window.location.href = playStoreLink;
      } else {
        const appleId = '6450213994';
        const appStoreLink = `itms-apps://itunes.apple.com/app/id${appleId}`;
        window.location.href = appStoreLink;
      }
    }
  };

  useEffect(() => {
    handleDeepLink();
  }, []);

  return <></>;
};

export default DeepLinkHandler;
