import { CSpinner } from '@coreui/react-pro';
import { lazy, Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import PageNotFound from '../pages/private/PageNotFound';
import Terms from '../pages/public/Pages/Terms';
import ResetPassword from '../pages/public/ResetPassword';
import VerificationEmail from '../pages/public/VerificationEmail';
import VerifyEmail from '../pages/public/VerifyEmail';
import PrivateRouter, { PrivateRouteProps } from './PrivateRouter';
import PublicRouter, { PublicRouteProps } from './PublicRouter';
import HyperPayWidget from '../hyperPayWidget/HyperPayWidget';
import PaymentCallBack from '../hyperPayWidget/paymentCallBack/PaymentCallBack';
import DeepLinkHandler from '../mobileModule/DeepLinkHandler';
import Booking from '../pages/public/Booking';
import OwnerRegister from '../pages/public/OwnerRegister';
import TamaraPaymentCallBack from '../hyperPayWidget/paymentCallBack/TamaraPaymentCallBack';
import CustomerPortal from '../pages/public/CustomerPortal';
import CustomerCheckout from '../pages/public/CustomerCheckout';
import GuestPaymentCallBack from '../hyperPayWidget/paymentCallBack/GuestPaymentCallBack';
// Containers
const DefaultLayout = lazy(() => import('../layout/DefaultLayout'));
const SignInPage = lazy(() => import('../pages/public/SignIn'));
const TransactionDetailsPage = lazy(() => import('../pages/public/TransactionDetails'));
const OtpPage = lazy(() => import('../pages/public/OtpScreen'));
const ForgotPasswordPage = lazy(() => import('../pages/public/ForgotPassword'));
// const ResetPasswordPage = lazy(() => import('../pages/public/ResetPassword'))
// const VerificationEmail = lazy(() => import('../pages/public/VerificationEmail'))
// const VerifyEmail = lazy(() => import('../pages/public/VerifyEmail'))

const IndexRouter = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
                }}
        >
          <CSpinner color="primary" />
        </div>
      }
    >
      <Router>
        <Routes>
          <Route path="/transaction-details" element={<TransactionDetailsPage />} />

          <Route path="/paymentCallBack" element={<HyperPayWidget />} />
          <Route path="/checkout" element={<CustomerCheckout />} />

          <Route path="/checkPaymentStatus" element={<PaymentCallBack />} />
          <Route path="/guest/checkPaymentStatus" element={<GuestPaymentCallBack />} />
          <Route path="/checkTamaraPaymentStatus" element={<TamaraPaymentCallBack />} />

          <Route path="/deepLinkHandler" element={<DeepLinkHandler />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/bbreg/:providerID" element={<OwnerRegister />} />
          
          <Route
            path="/signin"
            element={<PublicRouter {...defaultPublicRouteProps} outlet={<SignInPage />} />}
          />

          <Route
            path="/otp"
            element={<PublicRouter {...defaultPublicRouteProps} outlet={<OtpPage />} />}
          />

          <Route
            path="/forgot-password"
            element={<PublicRouter {...defaultPublicRouteProps} outlet={<ForgotPasswordPage />} />}
          />

          <Route path="/verification">
            <Route
              path="email"
              element={<PublicRouter {...defaultPublicRouteProps} outlet={<VerificationEmail />} />}
            />

            <Route path="*" index element={<PageNotFound />} />
          </Route>

          <Route path="/terms" element={<Terms />} />

          <Route path="/verify-email">
            <Route index element={<PageNotFound />} />

            <Route path="*" element={<VerifyEmail />} />
          </Route>

          <Route path="/reset-password">
            <Route index element={<PageNotFound />} />
            <Route
              path="*"
              // element={<PublicRouter {...defaultPublicRouteProps} outlet={<ResetPasswordPage />} />}
              element={<ResetPassword />}
            />
          </Route>

          <Route
            path="*"
            element={<PrivateRouter {...defaultPrivateRouteProps} outlet={<DefaultLayout />} />}
          />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default IndexRouter;

const defaultPublicRouteProps: Omit<PublicRouteProps, 'outlet'> = {
  authenticatedPath: '/',
};

const defaultPrivateRouteProps: Omit<PrivateRouteProps, 'outlet'> = {
  authenticationPath: '/signin',
};
