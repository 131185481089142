import { getOsEnv, getOsEnvOptional } from './libs/env';

/**
 * Environment variables
 */
const env = {
  node: getOsEnvOptional('APP_ENV') || 'local',
  env: getOsEnv('REACT_APP_ENV'),

  app: {
    backend_url: getOsEnv('REACT_APP_BACKEND_URL'),
    api_prefix: getOsEnv('REACT_APP_API_PREFIX'),
    api_prefix_email: getOsEnv('REACT_APP_API_PREFIX_EMAIL'),
    hyper_pay_widget: getOsEnv('REACT_APP_HYPER_PAY'),
    google_maps_api_key: getOsEnv('REACT_APP_GOOGLE_MAPS_API_KEY'),
    react_root_app: getOsEnv('REACT_APP_ROOT'),
  },
};

export default env;
