import request from '../config/request';

const prefix = '/provider';

export const FetchProvidersAPI = async (params: any) => {
  const responseType = params.format ? 'blob' : undefined;
  const response = await request({
    url: prefix + '/list',
    method: 'GET',
    params,
    responseType,
  });

  return response;
};

export const AddProviderAPI = async (body: any) => {
  const response = await request({
    url: prefix + '/create-provider',
    method: 'POST',
    body,
  });

  return response;
};

export const FetchProviderHistoryApi = async (params: any) => {
  const response = await request({
    url: prefix + `/actionHistory/${params?.providerId}`,
    method: 'GET',
  });

  return response;
};

export const AddFreelancerProviderAPI = async (body: any) => {
  const response = await request({
    url: prefix + '/create-freelancer-provider',
    method: 'POST',
    body,
  });

  return response;
};

export const GetProviderProfileAPI = async (params: any) => {
  if (params.providerId) {
    const response = await request({
      url: `${prefix}/${params.providerId}`,
    });

    return response;
  } else return null;
};

export const GetProviderSalonAPI = async (params: any) => {
  if (params.providerId) {
    const response = await request({
      url: `/salon-details/${params.providerId}`,
    });

    return response;
  } else return null;
};

export const GetOperatingDays = async (body: any) => {
  const response = await request({
    url: prefix + `/update-provider/${body.id}`,
    // url: prefix + `/update-provider/${body.providerId}`,
    method: 'GET',
  });

  return response;
};

export const GetTimeSlotsForProviderCategory = async (providerCategoryId: any) => {
  const response = await request({
    url: prefix + `/time-slot/get?providerCategoryId=${providerCategoryId}`,
    method: 'GET',
  });
  return response;
};

export const UpdateTimeSlotsForProviderCategory = async (body: any) => {
  const response = await request({
    url: prefix + `/time-slot/manage`,
    method: 'POST',
    body,
  });
  return response;
};

export const AddCategoriesApi = async (body: any) => {
  const response = await request({
    url: prefix + `/add-sub-category`,
    method: 'POST',
    body,
  });
  return response;
};

export const AddPackageApi = async (body: any) => {
  const response = await request({
    url: prefix + `/add-package`,
    method: 'POST',
    body,
  });
  return response;
};

export const UpdateProviderProfileAPI = async (body: any) => {
  const response = await request({
    url: prefix + `/update-provider/${body.id}`,
    // url: prefix + `/update-provider/${body.providerId}`,
    method: 'PUT',
    body,
  });

  return response;
};

export const ResendVerificationEmail = async (id: string) => {
  const response = await request({
    url: prefix + `/resend-email-verification-email/${id}`,
    // url: prefix + `/update-provider/${body.providerId}`,
    method: 'POST',
  });

  return response;
};

export const ToggleProviderActivationAPI = async (params: any) => {
  const response = await request({
    method: 'PUT',
    url: `${prefix}/toggle-provider-activation/${params.providerId}`,
  });

  return response;
};

export const ProviderPermissionAPI = async (body: any) => {
  const response = await request({
    method: 'PUT',
    url: `${prefix}/toggle-provider-permission/${body.id}`,
    body: body?.value,
  });

  return response;
};

export const ProviderChangeCommissionAPI = async (body: any) => {
  const response = await request({
    method: 'PUT',
    url: `${prefix}/change-commission`,
    body,
  });

  return response;
};

export const DeleteProviderApi = async (body: any) => {
  const response = await request({
    method: 'DELETE',
    url: `${prefix}/delete/${body.providerId}`,
    body,
  });

  return response;
};

export const GetProviderBookingProfileAPI = async (params: any) => {
  const response = await request({
    url: `${prefix}/booking/${params.providerId}`,
    params,
  });

  return response;
};

export const ProviderChangeBankDetailsAPI = async (body: any) => {
  const response = await request({
    method: 'PUT',
    url: `${prefix}/bank-details/${body.id}`,
    body,
  });

  return response;
};

export const InviteSalonOwner = async (body: any) => {
  const response = await request({
    url: prefix + `/invite-owner`,
    // url: prefix + `/update-provider/${body.providerId}`,
    method: 'POST',
    body,
  });

  return response;
};

export const OwnerRegistration = async (body: any) => {
  const response = await request({
    url: `/owner-registration/`,
    // url: prefix + `/update-provider/${body.providerId}`,
    method: 'POST',
    customURl: true,
    body,
  });

  return response;
};

export const SalonInfo = async (id: string) => {
  const response = await request({
    url: `/app/user/salon/${id}`,
    // url: prefix + `/update-provider/${body.providerId}`,
    method: 'GET',
    customURl: true,
  });

  return response;
};
