import axios, { ResponseType } from 'axios';
import _ from 'lodash';
import env from '../env';
import UseToast from '../hooks/useToast';

axios.interceptors.request.use(
  function (config) {
    if (!config.headers) {
      config.headers = {};
    }

    const token = localStorage.getItem('salon-admin-token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }

    config.headers['Content-Type'] = 'application/json';
    config.headers['Accept'] = 'application/json';
    config.headers['env'] = env.env;

    return config;
  },

  function (error) {
    return Promise.reject(error);
  },
);

const logOutCalled = () => {
  setTimeout(() => {
    localStorage.clear();
    window.location.replace('/signin');
  }, 2000);
};

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    if (_.get(response, 'headers["content-type"]').includes('application/json')) {
      response = response.data;

      if (_.get(response, 'status') && _.get(response, 'data.success')) {
        const token = _.get(response, 'data.token');
        if (token) {
          localStorage.setItem('salon-admin-token', token);
        }

        return response;
      } else {
        const message = _.get(response, 'data.message', 'Something went wrong.');

        UseToast(message, 'error');
        return Promise.reject(response);
      }
    } else if (response.status === 200) {
      return response;
    }

    return Promise.reject(response);
  },
  function (error) {
    console.log('🚀 ~ file: request.ts:62 ~ error:', error);
    if (error.response.status === 401) {
      logOutCalled();
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const message =
      _.get(error, 'response.status') === 404
        ? 'API not found.'
        : _.get(error, 'response.data.data.message', 'Something went wrong.');

    UseToast(message, 'error');

    if (_.get(error, 'response.status') == 409) return Promise.reject(error);

    return Promise.reject(message);
  },
);

const request = async ({
  url,
  method = 'GET',
  params,
  body,
  headers,
  responseType,
  customURl = false,
}: {
  url: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  params?: any;
  body?: any;
  headers?: any;
  responseType?: ResponseType;
  customURl?: boolean;
}) => {
  const BASE_URL = `${env.app.backend_url}${customURl ? '/api/v1' : env.app.api_prefix}`;
  // const BASE_URL = `${env.app.backend_url}${env.app.api_prefix}${env.app.api_prefix_email}`

  const res = await axios.request({
    url: BASE_URL + url,
    method,
    params,
    data: body,
    headers,
    responseType,
  });

  return res;
};
export const requestForEmail = async ({
  url,
  method = 'GET',
  params,
  body,
  headers,
  responseType,
}: {
  url: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  params?: any;
  body?: any;
  headers?: any;
  responseType?: ResponseType;
}) => {
  const BASE_URL = `${env.app.backend_url}${env.app.api_prefix_email}`;

  const res = await axios.request({
    url: BASE_URL + url,
    method,
    params,
    data: body,
    headers,
    responseType,
  });

  return res;
};

export default request;
