import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { validatePayment } from '../../api/common.api';
import { AxiosResponse } from 'axios';
import { FaCheckCircle } from 'react-icons/fa';
import { MdError } from 'react-icons/md';
import { CardHeader } from 'react-bootstrap';
import { CCol, CImage, CRow, CSpinner } from '@coreui/react-pro';
import appStore from '../../assets/stores/app-store.svg';
import googlePlay from '../../assets/stores/google-play.svg';
import headerLogo from '../../assets/brand/mainLogo2.svg';

declare global {
  interface Window {
    ReactNativeWebView?: any;
    webkit?: any;
    wpwlOptions?: any;
  }
}

interface ResponseData {
  message: string;
  code: string;
  description: string;
  hyperpaySucess: boolean;
  success: boolean;
}

interface IApiResponse {
  data: ResponseData;
}

const GuestPaymentCallBack = () => {
  const [searchParams] = useSearchParams();
  const [paidSuccessfully, setPaidSuccessfully] = useState<boolean>(false);

  const { mutate: mutateValidatePayment, isLoading } = useMutation(validatePayment);

  const packageName = 'com.beautybook.customer';
  const appleId = '6450213994';

  const appStoreLink = `itms-apps://itunes.apple.com/app/id${appleId}`;
  const playStoreLink = `market://details?id=${packageName}`;

  useEffect(() => {
    try {
      const CheckOutId: string = searchParams.get('id') ?? '';

      const postData = {
        type: 'payment',
        payload: { id: CheckOutId, success: true },
      };

      mutateValidatePayment(
        {
          checkoutId: CheckOutId,
        },
        {
          onSuccess: (res: IApiResponse) => {
            if (res.data.hyperpaySucess) {
              setPaidSuccessfully(true);
              if (window.ReactNativeWebView) {
                window.ReactNativeWebView?.postMessage(JSON.stringify(postData));
                return;
              }

              if (
                window.webkit &&
                window.webkit.messageHandlers &&
                window.webkit.messageHandlers.ReactNativeWebView
              ) {
                window.webkit.messageHandlers.ReactNativeWebView.postMessage(
                  JSON.stringify(postData),
                );
                return;
              }
            } else {
              if (window.ReactNativeWebView) {
                window.ReactNativeWebView?.postMessage(
                  JSON.stringify({
                    success: false,
                    Description: String(res.data.description),
                  }),
                );

                return;
              }

              if (
                window.webkit &&
                window.webkit.messageHandlers &&
                window.webkit.messageHandlers.ReactNativeWebView
              ) {
                window.webkit.messageHandlers.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    success: false,
                    Description: String(res.data.description),
                  }),
                );

                return;
              }
            }
          },

          onError: (err) => {
            window.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: 'payment',
                payload: {
                  id: CheckOutId,
                  success: false,
                  error: err,
                },
              }),
            );
          },
        },
      );
    } catch (e) {
      console.error('react app', e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="main-container">
      <CardHeader className="main-header">
        <CImage
          width={'200px'}
          height={'150px'}
          align="start"
          alt="logo"
          src={headerLogo}
          className="profile-picture"
        />
      </CardHeader>

      <div className="main-content">
        {isLoading ? (
          <CRow className="justify-content-center mt-5 text-center">
            <CSpinner className="text-center mb-3" color="primary" />
            <h3>{'loading ...'}</h3>
          </CRow>
        ) : (
          <div className="page-container">
            <div className="page-content">
              {paidSuccessfully ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginTop: '50px',
                  }}
                >
                  <FaCheckCircle size={100} color="green" style={{ marginBottom: '20px' }} />
                  <h3>{'Payment Success'}</h3>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginTop: '50px',
                  }}
                >
                  <MdError size={100} color="red" style={{ marginBottom: '20px' }} />
                  <h3>{'Payment Failed'}</h3>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="main-footer">
        <a href={appStoreLink} className="store-container">
          <img src={appStore} alt="appStore" className="store-img" />
        </a>
        <a href={playStoreLink} className="store-container">
          <img src={googlePlay} alt="googlePlay" className="store-img" />
        </a>
      </div>
    </div>
  );

  // return isLoading ? (
  //   <div
  //     style={{
  //       display: 'flex',
  //       justifyContent: 'center',
  //       alignItems: 'center',
  //       flexDirection: 'column',
  //       height: '100vh',
  //     }}
  //   >
  //     <h3>{'loading ...'}</h3>
  //   </div>
  // ) : paidSuccessfully ? (
  //   <div
  //     style={{
  //       display: 'flex',
  //       justifyContent: 'center',
  //       alignItems: 'center',
  //       flexDirection: 'column',
  //       height: '100vh',
  //     }}
  //   >
  //     <FaCheckCircle size={100} color="green" style={{ marginBottom: '20px' }} />
  //     <h3>{'Payment Success'}</h3>
  //   </div>
  // ) : (
  //   <div
  //     style={{
  //       display: 'flex',
  //       justifyContent: 'center',
  //       alignItems: 'center',
  //       flexDirection: 'column',
  //       height: '100vh',
  //     }}
  //   >
  //     <MdError size={100} color="red" style={{ marginBottom: '20px' }} />
  //     <h3>{'Payment Failed'}</h3>
  //   </div>
  // );
};

export default GuestPaymentCallBack;
