import React from 'react';
import CheckOutPayment from './checkOutPayment/CheckOutPayment';
import { useSearchParams } from 'react-router-dom';
import './hyperPayWidget.scss';
import AppHeader from '../components/AppHeader';
import AppFooter from '../components/AppFooter';

const HyperPayWidget = () => {
  const [searchParams] = useSearchParams();

  const local = searchParams.get('local') || 'ar';

  return (
    <div className="main-container">
      <AppHeader />
      <div className="HyperPayWidget">
        <div id="order-payment" dir={local === 'ar' ? 'rtl' : 'ltr'} className="order-pay">
          <CheckOutPayment
            appendId={'order-payment'}
            paymentMehtodSelected={searchParams.get('brand') || ''}
            checkoutId={searchParams.get('checkoutId') || ''}
            invoiceTotalAmount={searchParams.get('amount') || ''}
            local={local}
            baseUrl={searchParams.get('baseUrl') || ''}
            // isProduction={searchParams.get('isProduction') || 'false'}
          />
        </div>
      </div>

 
    </div>
  );
};

export default HyperPayWidget;
