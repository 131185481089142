import { CImage } from '@coreui/react-pro'
import React from 'react'
import { CardHeader } from 'react-bootstrap'
import headerLogo from '../../assets/brand/mainLogo2.svg'
function AppHeader() {
  return (
    <CardHeader className="main-header">
    <CImage
      width={'200px'}
      height={'150px'}
      align="start"
      alt="logo"
      src={headerLogo}
      className="profile-picture"
    />
  </CardHeader>
  )
}

export default AppHeader